import React, { forwardRef } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = forwardRef(({ severity, children }, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" severity={severity}>{children}</MuiAlert>
));

const CustomizedSnackbars = ({
  msg,
  alertType,
  open,
  setOpen,
}) => {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={alertType} sx={{ width: '100%' }}>{msg}</Alert>
    </Snackbar>
  );
};

export default CustomizedSnackbars;
