import COLORS from './Colors';

const box = {
  display: 'flex',
  flexFlow: 'column',
  height: '100%',
};
const contentBox = {
  flex: '1 1 auto',
};
const headerBox = {
  flex: '0 1 auto',
};

const styles = ({
  /* Generic styles */
  // Box
  initBox: {
    ...box,
    width: '100%',
    position: 'absolute',
  },
  box: { ...box },
  headerBox: { ...headerBox },
  contentBox: { ...contentBox },
  boxContentBox: {
    ...box,
    ...contentBox,
  },
  // Display properties
  displayBlock: { display: 'block' },
  displayFlex: { display: 'flex' },
  flexGrow1: { flexGrow: 1 },
  alignItemsCenter: { alignItems: 'center' },
  justifyContentCenter: { justifyContent: 'center' },
  justifyContentBetween: { justifyContent: 'space-between' },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexCenterStart: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  flexCenterBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  flexEndCenter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  // Positioning properties
  positionRelative: { position: 'relative' },
  positionAbsolute: { position: 'absolute' },
  positionSticky: { position: 'sticky' },
  top0: { top: '0' },
  right0: { right: '0' },
  bottom0: { bottom: '0' },
  left0: { left: '0' },
  // Text Align
  textLeft: { textAlign: 'left' },
  textCenter: { textAlign: 'center' },
  textRight: { textAlign: 'right' },
  // Text Transform
  textTransformNone: { textTransform: 'none' },
  textTransformCapitalize: { textTransform: 'capitalize' },
  textTransformUppercase: { textTransform: 'uppercase' },
  textTransformLowercase: { textTransform: 'lowercase' },
  // Margin
  noneMargin: { margin: '0' },
  noneMT: { marginTop: '0' },
  noneMR: { marginRight: '0' },
  noneMB: { marginBottom: '0' },
  noneML: { marginLeft: '0' },
  halfRemMargin: { margin: '0.5rem' },
  oneRemMT: { marginTop: '1rem' },
  twoRemMT: { marginTop: '2rem' },
  quarterRemML: { marginLeft: '0.25rem' },
  halfRemML: { marginLeft: '0.5rem' },
  oneRemML: { marginLeft: '1rem' },
  halfRemMR: { marginRight: '0.5rem' },
  oneRemMR: { marginRight: '1rem' },
  // Padding
  nonePadding: { padding: '0' },
  quarterRemPadding: { padding: '0.25rem' },
  halfRemPadding: { padding: '0.5rem' },
  nonePY: {
    paddingTop: '0',
    paddingBottom: '0',
  },
  twoPixelsPY: {
    paddingTop: '0.125rem',
    paddingBottom: '0.125rem',
  },
  quarterRemPY: {
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
  },
  halfRemPY: {
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
  quarterRemPX: {
    paddingLeft: '0.25rem',
    paddingRight: '0.25rem',
  },
  halfRemPX: {
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
  },
  oneRemPB: { paddingBottom: '1rem' },
  halfRemPL: { paddingLeft: '0.5rem' },
  oneRemPL: { paddingLeft: '1rem' },
  halfRemPR: { paddingRight: '0.5rem' },
  oneRemPR: { paddingRight: '1rem' },
  // Height
  height100: { height: '100%' },
  // Width
  width100: { width: '100%' },
  minWidthAuto: { minWidth: 'auto' },
  widthAuto: { width: 'auto' },
  maxWidthMaxContent: { maxWidth: 'max-content' },
  minWidthFiveRem: { minWidth: '5rem' },
  minWidthSixRem: { minWidth: '6rem' },
  minWidthSevenRem: { minWidth: '7rem' },
  minWidthEightRem: { minWidth: '8rem' },
  minWidthNineRem: { minWidth: '9rem' },
  minWidthTenRem: { minWidth: '10rem' },
  minWidthElevenRem: { minWidth: '11rem' },
  minWidthTwelveRem: { minWidth: '12rem' },
  minWidthThirteenRem: { minWidth: '13rem' },
  minWidthFourteenRem: { minWidth: '14rem' },
  minWidthFifteenRem: { minWidth: '15rem' },
  minWidthSixteenRem: { minWidth: '16rem' },
  minWidthSeventeenRem: { minWidth: '17rem' },
  minWidthEighteenRem: { minWidth: '18rem' },
  minWidthNineteenRem: { minWidth: '19rem' },
  minWidthTwentyRem: { minWidth: '20rem' },
  // Background Colors
  backgroundSlate50: { backgroundColor: COLORS.SLATE50 },
  backgroundSlate100: { backgroundColor: COLORS.SLATE100 },
  backgroundSlate200: { backgroundColor: COLORS.SLATE200 },
  backgroundSlate300: { backgroundColor: COLORS.SLATE300 },
  backgroundSlate400: { backgroundColor: COLORS.SLATE400 },
  backgroundSlate500: { backgroundColor: COLORS.SLATE500 },
  backgroundSlate600: { backgroundColor: COLORS.SLATE600 },
  backgroundSlate700: { backgroundColor: COLORS.SLATE700 },
  backgroundSlate800: { backgroundColor: COLORS.SLATE800 },
  backgroundSlate900: { backgroundColor: COLORS.SLATE900 },
  // TextColors
  textColorInherit: { color: 'inherit' },
  textEmerald50: { color: COLORS.EMERALD50 },
  textEmerald100: { color: COLORS.EMERALD100 },
  textEmerald200: { color: COLORS.EMERALD200 },
  textEmerald300: { color: COLORS.EMERALD300 },
  textEmerald400: { color: COLORS.EMERALD400 },
  textEmerald500: { color: COLORS.EMERALD500 },
  textEmerald600: { color: COLORS.EMERALD600 },
  textEmerald700: { color: COLORS.EMERALD700 },
  textEmerald800: { color: COLORS.EMERALD800 },
  textEmerald900: { color: COLORS.EMERALD900 },
  textGray50: { color: COLORS.GRAY50 },
  textGray100: { color: COLORS.GRAY100 },
  textGray200: { color: COLORS.GRAY200 },
  textGray300: { color: COLORS.GRAY300 },
  textGray400: { color: COLORS.GRAY400 },
  textGray500: { color: COLORS.GRAY500 },
  textGray600: { color: COLORS.GRAY600 },
  textGray700: { color: COLORS.GRAY700 },
  textGray800: { color: COLORS.GRAY800 },
  textGray900: { color: COLORS.GRAY900 },
  textIndigo50: { color: COLORS.INDIGO50 },
  textIndigo100: { color: COLORS.INDIGO100 },
  textIndigo200: { color: COLORS.INDIGO200 },
  textIndigo300: { color: COLORS.INDIGO300 },
  textIndigo400: { color: COLORS.INDIGO400 },
  textIndigo500: { color: COLORS.INDIGO500 },
  textIndigo600: { color: COLORS.INDIGO600 },
  textIndigo700: { color: COLORS.INDIGO700 },
  textIndigo800: { color: COLORS.INDIGO800 },
  textIndigo900: { color: COLORS.INDIGO900 },
  textNeutral50: { color: COLORS.NEUTRAL50 },
  textNeutral100: { color: COLORS.NEUTRAL100 },
  textNeutral200: { color: COLORS.NEUTRAL200 },
  textNeutral300: { color: COLORS.NEUTRAL300 },
  textNeutral400: { color: COLORS.NEUTRAL400 },
  textNeutral500: { color: COLORS.NEUTRAL500 },
  textNeutral600: { color: COLORS.NEUTRAL600 },
  textNeutral700: { color: COLORS.NEUTRAL700 },
  textNeutral800: { color: COLORS.NEUTRAL800 },
  textNeutral900: { color: COLORS.NEUTRAL900 },
  textOrange50: { color: COLORS.ORANGE50 },
  textOrange100: { color: COLORS.ORANGE100 },
  textOrange200: { color: COLORS.ORANGE200 },
  textOrange300: { color: COLORS.ORANGE300 },
  textOrange400: { color: COLORS.ORANGE400 },
  textOrange500: { color: COLORS.ORANGE500 },
  textOrange600: { color: COLORS.ORANGE600 },
  textOrange700: { color: COLORS.ORANGE700 },
  textOrange800: { color: COLORS.ORANGE800 },
  textOrange900: { color: COLORS.ORANGE900 },
  textRose50: { color: COLORS.ROSE50 },
  textRose100: { color: COLORS.ROSE100 },
  textRose200: { color: COLORS.ROSE200 },
  textRose300: { color: COLORS.ROSE300 },
  textRose400: { color: COLORS.ROSE400 },
  textRose500: { color: COLORS.ROSE500 },
  textRose600: { color: COLORS.ROSE600 },
  textRose700: { color: COLORS.ROSE700 },
  textRose800: { color: COLORS.ROSE800 },
  textRose900: { color: COLORS.ROSE900 },
  textSky50: { color: COLORS.SKY50 },
  textSky100: { color: COLORS.SKY100 },
  textSky200: { color: COLORS.SKY200 },
  textSky300: { color: COLORS.SKY300 },
  textSky400: { color: COLORS.SKY400 },
  textSky500: { color: COLORS.SKY500 },
  textSky600: { color: COLORS.SKY600 },
  textSky700: { color: COLORS.SKY700 },
  textSky800: { color: COLORS.SKY800 },
  textSky900: { color: COLORS.SKY900 },
  textSlate50: { color: COLORS.SLATE50 },
  textSlate100: { color: COLORS.SLATE100 },
  textSlate200: { color: COLORS.SLATE200 },
  textSlate300: { color: COLORS.SLATE300 },
  textSlate400: { color: COLORS.SLATE400 },
  textSlate500: { color: COLORS.SLATE500 },
  textSlate600: { color: COLORS.SLATE600 },
  textSlate700: { color: COLORS.SLATE700 },
  textSlate800: { color: COLORS.SLATE800 },
  textSlate900: { color: COLORS.SLATE900 },
  textStone50: { color: COLORS.STONE50 },
  textStone100: { color: COLORS.STONE100 },
  textStone200: { color: COLORS.STONE200 },
  textStone300: { color: COLORS.STONE300 },
  textStone400: { color: COLORS.STONE400 },
  textStone500: { color: COLORS.STONE500 },
  textStone600: { color: COLORS.STONE600 },
  textStone700: { color: COLORS.STONE700 },
  textStone800: { color: COLORS.STONE800 },
  textStone900: { color: COLORS.STONE900 },
  textTeal50: { color: COLORS.TEAL50 },
  textTeal100: { color: COLORS.TEAL100 },
  textTeal200: { color: COLORS.TEAL200 },
  textTeal300: { color: COLORS.TEAL300 },
  textTeal400: { color: COLORS.TEAL400 },
  textTeal500: { color: COLORS.TEAL500 },
  textTeal600: { color: COLORS.TEAL600 },
  textTeal700: { color: COLORS.TEAL700 },
  textTeal800: { color: COLORS.TEAL800 },
  textTeal900: { color: COLORS.TEAL900 },
  textYellow50: { color: COLORS.YELLOW50 },
  textYellow100: { color: COLORS.YELLOW100 },
  textYellow200: { color: COLORS.YELLOW200 },
  textYellow300: { color: COLORS.YELLOW300 },
  textYellow400: { color: COLORS.YELLOW400 },
  textYellow500: { color: COLORS.YELLOW500 },
  textYellow600: { color: COLORS.YELLOW600 },
  textYellow700: { color: COLORS.YELLOW700 },
  textYellow800: { color: COLORS.YELLOW800 },
  textYellow900: { color: COLORS.YELLOW900 },
  // font Styles
  fontWeightBold: { fontWeight: 'bold' },
  fontWeightNormal: { fontWeight: 'normal' },
  fontWeightLighter: { fontWeight: 'lighter' },
  fontSizeOneRem: { fontSize: '1rem' },
  // Button action styles
  actionText: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  // Border styles
  borderSlate700: { border: `1px solid ${COLORS.SLATE700}` },
  doubleBorderSlate500: { borderBottom: `2px solid ${COLORS.SLATE500}` },
  doubleBorderSlate700: { border: `2px solid ${COLORS.SLATE700}` },
  doubleBorderEmerald300: { border: `2px solid ${COLORS.EMERALD300}` },
  doubleBorderEmerald400: { border: `2px solid ${COLORS.EMERALD400}` },
  quarterRemBorderSlate700: { border: `0.25rem solid ${COLORS.SLATE700}` },
  quarterRemBorderEmerald300: { border: `0.25rem solid ${COLORS.EMERALD300}` },
  quarterRemBorderEmerald400: { border: `0.25rem solid ${COLORS.EMERALD400}` },
  quarterRemBorderGray50: { border: `0.25rem solid ${COLORS.GRAY50}` },
  quarterRemBorderGray100: { border: `0.25rem solid ${COLORS.GRAY100}` },
  quarterRemBorderGray200: { border: `0.25rem solid ${COLORS.GRAY200}` },
  quarterRemBorderGray300: { border: `0.25rem solid ${COLORS.GRAY300}` },
  quarterRemBorderGray400: { border: `0.25rem solid ${COLORS.GRAY400}` },
  quarterRemBorderGray500: { border: `0.25rem solid ${COLORS.GRAY500}` },
  quarterRemBorderGray600: { border: `0.25rem solid ${COLORS.GRAY600}` },
  quarterRemBorderGray700: { border: `0.25rem solid ${COLORS.GRAY700}` },
  quarterRemBorderGray800: { border: `0.25rem solid ${COLORS.GRAY800}` },
  quarterRemBorderGray900: { border: `0.25rem solid ${COLORS.GRAY900}` },
  quarterRemBorderRed400: { border: `0.25rem solid ${COLORS.RED400}` },
  quarterRemBorderRose500: { border: `0.25rem solid ${COLORS.ROSE500}` },
  // BorderBottom styles
  borderBottomSlate100: { borderBottom: `1px solid ${COLORS.SLATE100}` },
  borderBottomSlate600: { borderBottom: `1px solid ${COLORS.SLATE600}` },
  borderBottomSlate700: { borderBottom: `1px solid ${COLORS.SLATE700}` },
  borderBottomNeutral300: { borderBottom: `1px solid ${COLORS.NEUTRAL300}` },
  borderBottomNeutral500: { borderBottom: `1px solid ${COLORS.NEUTRAL500}` },
  borderBottomNeutral600: { borderBottom: `1px solid ${COLORS.NEUTRAL600}` },
  borderBottomStone500: { borderBottom: `1px solid ${COLORS.STONE500}` },
  borderBottomStone600: { borderBottom: `1px solid ${COLORS.STONE600}` },
  // Border radius styles
  borderRadius0: { borderRadius: '0' },
  borderRadius1: { borderRadius: '0.125rem' },
  borderRadius2: { borderRadius: '0.25rem' },
  borderRadius3: { borderRadius: '0.375rem' },
  borderRadius4: { borderRadius: '0.5rem' },
  borderRadius5: { borderRadius: '0.625rem' },
  borderRadius6: { borderRadius: '0.75rem' },
  borderRadius7: { borderRadius: '0.875rem' },
  borderRadius8: { borderRadius: '1rem' },
  // Overflow properties
  overflowXAuto: { overflowX: 'auto' },
  // PointerEvents
  pointerEventsNone: { pointerEvents: 'none' },

  /* Tables styles */
  emerald600IconTooltip: {
    color: COLORS.EMERALD600,
    marginRight: '0.5rem',
  },
  neutral500IconTooltip: {
    color: COLORS.NEUTRAL500,
    marginRight: '0.5rem',
  },
  yellow600IconTooltip: {
    color: COLORS.YELLOW600,
    marginRight: '0.5rem',
  },
  red700IconTooltip: {
    color: COLORS.RED700,
    marginRight: '0.5rem',
  },
  green800IconTooltip: {
    color: COLORS.GREEN800,
    marginRight: '0.5rem',
  },
});

export default styles;
