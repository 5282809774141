import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import {
  styled,
  Box,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TrackIcon from '@mui/icons-material/MapOutlined';
import CombiIcon from '@mui/icons-material/AirportShuttleOutlined';
import BeginTrackIcon from '@mui/icons-material/FactCheckOutlined';
import DriverIcon from '@mui/icons-material/BadgeOutlined';
import COLORS from '../../Colors';

const Typography18 = styled(Typography)(() => ({ fontSize: 18 }));

const StyledDialogTitle = styled(DialogTitle)(() => ({
  width: 'calc(100% - 6rem)',
}));

const CancelStyledButton = styled(Button)(() => ({
  textTransform: 'none',
  fontSize: 18,
  transition: 'all 0.4s ease-out',
  '&:hover': {
    backgroundColor: COLORS.RED100,
    transition: 'all 0.4s ease-in',
  },
}));

const AlertDialog = ({
  modalIdTitle,
  infoPanel,
  isModalOpen,
  isButtonCancel,
  dialogType,
  liftingActionConfirm,
  handleClose,
}) => {
  const ButtonCancel = (isButtonCancel) ? (
    <DialogActions>
      <Button onClick={handleClose}>Cancelar</Button>
    </DialogActions>
  ) : null;

  const {
    trackId,
    track,
    unidad,
    beginTrack,
    driver,
  } = infoPanel;

  const sxBorderBottom = { borderBottomWidth: '2px', borderBottomStyle: 'solid', borderBottomColor: `${COLORS.GRAY300}` };
  const panelLabelStyle = {
    display: 'flex',
    alignItems: 'center',
    color: `${COLORS.ZINC500}`,
    fontSize: 16,
    fontWeight: 'bold',
  };
  const actionTitleObj = {
    terminado: 'Recorrido a terminar',
    cancelado: 'Recorrido a cancelar',
  };
  const actionTitle = actionTitleObj[dialogType] ?? '';
  const actionButtonObj = {
    terminado: (
      <CancelStyledButton
        color="primary"
        onClick={() => liftingActionConfirm(trackId, dialogType)}
        disableElevation
        size="large"
      >
        Terminar recorrido
      </CancelStyledButton>),
    cancelado: (
      <CancelStyledButton
        color="error"
        onClick={() => liftingActionConfirm(trackId, dialogType)}
        disableElevation
        size="large"
      >
        Cancelar recorrido
      </CancelStyledButton>),
  };
  const actionButton = actionButtonObj[dialogType] ?? null;

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleClose}
      aria-labelledby={`alert-dialog-${modalIdTitle}`}
      fullWidth
      maxWidth="xs"
    >
      <Box sx={{ ...sxBorderBottom, display: 'flex' }}>
        <StyledDialogTitle id={`alert-dialog-${modalIdTitle}`}>
          {actionTitle}
        </StyledDialogTitle>
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent sx={{ ...sxBorderBottom }}>
        <Box sx={{ ...panelLabelStyle }}>
          <TrackIcon sx={{ mr: 1 }} fontSize="small" />
          Recorrido
        </Box>
        <Typography18 variant="h6" gutterBottom component="div" sx={{ mb: 1 }}>{` ${track ?? ''}`}</Typography18>
        <Box sx={{ ...panelLabelStyle }}>
          <CombiIcon sx={{ mr: 1 }} fontSize="small" />
          Unidad
        </Box>
        <Typography18 variant="h6" gutterBottom component="div" sx={{ mb: 1 }}>{ `${unidad ?? ''}`}</Typography18>
        <Box sx={{ ...panelLabelStyle }}>
          <BeginTrackIcon sx={{ mr: 1 }} fontSize="small" />
          Salida
        </Box>
        <Typography18 variant="h6" gutterBottom component="div" sx={{ mb: 1 }}>{`${(new Date(beginTrack)).toLocaleTimeString() ?? ''}`}</Typography18>
        <Box sx={{ ...panelLabelStyle }}>
          <DriverIcon sx={{ mr: 1 }} fontSize="small" />
          Conductor
        </Box>
        <Typography18 variant="h6" gutterBottom component="div">{` ${driver ?? ''}`}</Typography18>
      </DialogContent>
      <DialogActions>
        {actionButton}
      </DialogActions>
      {ButtonCancel}
    </Dialog>
  );
};

export default AlertDialog;
