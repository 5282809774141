/** @jsxImportSource @emotion/react */
// import { Typography } from '@mui/material';
import { Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
// import { Navigate } from 'react-router-dom';
import axios from 'axios';
import { io } from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import MainAppBar from './Components/AppBar';
import MainTable from './Components/CollapsibleTable';
import MainStyles from './Components/styles/GeneralStyles';
import DialogItem from './Components/basics/DialogConfirmAction';
import Alert from './Components/Alert';

import SECRETS from './secrets';

const { initBox } = MainStyles;

const socket = io(SECRETS.SOCKETURL);

const twoDigits = (num) => {
  const numTwoDigits = (num <= 9) ? `0${num}` : `${num}`;
  return numTwoDigits;
};

const MainScreen = ({ id, token, liftingSession }) => {
  const [isLogged, setIsLogged] = useState(true);
  const [unidadesArray, setUnidadesArray] = useState([]);
  const [pointsRoute, setPointsRoute] = useState({});
  const [currentTracks, setCurrentTracks] = useState([]);
  const [rutas, setRutas] = useState([]);
  const [filterString, setFilterString] = useState('');
  const [filtroRutas, setFiltroRutas] = useState([]);
  const [checadas, setChecadas] = useState({});
  // Alert
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const [alertType, setAlertType] = useState('');
  const navigate = useNavigate();
  // Dialog
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState(false);
  const [infoPanel, setInfoPanel] = useState({});
  const visibleStateRows = [1];

  useEffect(() => {
    if (id <= 0 && token.length === 0) {
      setIsLogged(false);
    }
  });

  useEffect(() => {
    axios.defaults.headers.common.Authorization = `bearer ${token}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .get('/controlruta')
      .then((response) => {
        setRutas(response.data);
        const ObjToPush = {};
        const arrayIds = [];
        response.data.forEach(({ id: idControlRuta }) => {
          arrayIds.push(idControlRuta);
          axios.defaults.headers.common.Authorization = `bearer ${token}`;
          axios.defaults.baseURL = SECRETS.SERVERURL;
          axios
            .get(`/controlpunto/byRuta/${idControlRuta}`)
            .then((resp) => {
              const newObjWithPoint = {
                [idControlRuta]: resp.data || [],
              };
              Object.assign(ObjToPush, newObjWithPoint);
              setPointsRoute(ObjToPush);
            })
            .catch((e) => {
              console.error(e);
            });
        });
        setFiltroRutas(arrayIds);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  useEffect(() => {
    const getStateRow = (cancel, finished) => {
      if (cancel) { return 'cancelado'; }
      if (finished) { return 'terminado'; }
      return 'activo';
    };
    axios.defaults.headers.common.Authorization = `bearer ${token}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .get('/unidades/organizacion')
      .then((response) => {
        const unitsArray = response.data;
        const unitsSorted = unitsArray.sort((a, b) => a.unidad.localeCompare(b.unidad));
        setUnidadesArray(unitsSorted);
        return unitsSorted;
      }).then(() => {
        const date = new Date();
        axios.defaults.headers.common.Authorization = `bearer ${token}`;
        axios.defaults.baseURL = SECRETS.SERVERURL;
        axios
          .post('/controlrecorrido', {
            fecha: `${date.getFullYear()}-${twoDigits(date.getMonth() + 1)}-${twoDigits(date.getDate())}`,
          })
          .then((response) => {
            const tableArray = response.data.map((track) => {
              const {
                nombreRuta,
                inicio,
                nombreOperador,
                cancelado,
                terminado,
                puntos,
              } = track;
              const stateRowObj = {
                cancelado: 0,
                activo: 1,
                terminado: 2,
              };
              const stateRow = stateRowObj[getStateRow(cancelado, terminado)];
              return ({
                ...track,
                puntos,
                editable: false,
                textFilter: true,
                track: nombreRuta,
                trackInit: inicio,
                driver: nombreOperador,
                lastPoint: '-',
                diff: '-',
                points: [],
                selectFilter: true,
                stateRow,
              });
            }).sort((a, b) => {
              if (a.trackInit > b.trackInit) { return -1; }
              if (a.trackInit < b.trackInit) { return 1; }
              return 0;
            });
            return tableArray;
          })
          .then((response) => {
            setCurrentTracks(response);
            setMsg('Tabla cargada');
            setAlertType('success');
            setOpen(true);
          })
          .catch((e) => {
            console.error(e);
          });
      });
  }, []);

  // socket effect
  useEffect(() => {
    socket.on('connect', () => {
      console.log('connected');
      socket.emit('login', token);
    });
    socket.on('disconnect', () => {
      console.log(socket.id);
    });
    // socket.on('location', (data) => {
    //   console.log(data);
    // });
    socket.on('PuntoControl', (data) => {
      const { imei } = data;
      console.log(`${imei} s:${new Date(data.date).toLocaleString('es-MX', { time: 'short' })} r:${new Date().toLocaleString('es-MX', { time: 'short' })}`);
      setChecadas((chec) => {
        const clone = { ...chec };
        if (typeof clone[imei] === 'undefined') {
          clone[imei] = {
            latest: data,
            list: [data],
          };
          // console.log(clone);
          return clone;
        }
        // console.log(clone);
        clone[imei].latest = data;
        clone[imei].list.push(data);
        // console.log(clone);
        return clone;
      });
    });
    socket.on('status', ({ status, recorrido }) => {
      setCurrentTracks((ct) => {
        const clone = [...ct];
        // console.log(ct);
        let index = -1;
        const stateRowObj = {
          cancelado: 0,
          activo: 1,
          terminado: 2,
        };
        switch (status) {
          case 'cancelado':
          case 'terminado':
            index = clone.findIndex((c) => c.id === recorrido.id);
            if (index >= 0) {
              clone[index] = {
                ...clone[index],
                cancelado: (status === 'cancelado'),
                terminado: (status === 'terminado'),
                stateRow: stateRowObj[status],
                // Se puede actualizar fecha/hora de fin
              };
            }
            return clone;
          case 'nuevo':
            return [
              {
                ...recorrido,
                editable: false,
                textFilter: true,
                track: recorrido.nombreRuta,
                trackInit: recorrido.inicio,
                driver: recorrido.nombreOperador,
                lastPoint: '-',
                diff: '-',
                points: [],
                selectFilter: true,
                stateRow: 1,
              },
              ...clone,
            ];
          default:
            console.log('default');
        }
        return ct;
      });
    });
  }, []);

  useEffect(() => {
    if (!isLogged) {
      liftingSession(0, '');
      navigate('/');
    }
  }, [isLogged]);

  const liftingAction = (recorridoId, action) => {
    const {
      id: trackId,
      track,
      trackInit,
      imei,
      driver,
    } = currentTracks.find((item) => item.id === recorridoId);
    setInfoPanel({
      trackId,
      track,
      unidad: unidadesArray.find((u) => `${u.imei}` === `${imei}`)?.unidad ?? '',
      trackInit,
      driver,
    });
    setDialogType(action);
    setIsDialogOpen(true);
  };

  const liftingActionConfirm = (recorridoId, action) => {
    setIsDialogOpen(false);
    // setLoading(true);
    const getTZUTCDate = () => {
      const now = new Date();
      const UTCYear = now.getUTCFullYear();
      const UTCMonth = twoDigits(now.getUTCMonth() + 1);
      const UTCDay = twoDigits(now.getUTCDate());
      const UTCHour = twoDigits(now.getUTCHours());
      const UTCMinutes = twoDigits(now.getUTCMinutes());
      return `${UTCYear}-${UTCMonth}-${UTCDay}T${UTCHour}:${UTCMinutes}:00Z`;
    };
    axios.defaults.headers.common.Authorization = `bearer ${token}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    const actionObj = {
      cancelado: { [action]: true },
      terminado: { [action]: true, fin: getTZUTCDate() },
    };
    const actionObjSelected = actionObj[action] || {};
    axios
      .patch(`/controlrecorrido/${recorridoId}`, actionObjSelected)
      .then(() => {
        const clone = [...currentTracks];
        const index = clone.findIndex((c) => c.id === recorridoId);
        if (index >= 0) {
          const stateRowObj = { cancelado: 0, terminado: 2 };
          clone[index].stateRow = stateRowObj[action];
          // setLoading(false);
          // clone.splice(index, 1);
          setCurrentTracks(clone);
          socket.emit('status', {
            code: SECRETS.SOCKETCODE,
            status: action,
            recorrido: {
              id: recorridoId,
            },
          });
          const msgDisplayObj = {
            terminado: 'Recorrido finalizado',
            cancelado: 'Recorrido cancelado',
          };
          const msgDisplay = msgDisplayObj[action] ?? 'Se han actualizado los datos';
          setMsg(msgDisplay);
          setAlertType('success');
          setOpen(true);
        }
      })
      .catch((e) => {
        // setLoading(false);
        setMsg('Sin conexión a internet');
        setAlertType('error');
        console.error(e);
        if (e.response.status === 401) {
          // setId(0);
          // setToken('');
        }
      });
  };

  const liftingSearch = (searchValue) => setFilterString(searchValue);

  return (
    <Container
      css={initBox}
      maxWidth={false}
      disableGutters
    >
      <DialogItem
        modalIdTitle="actions-track"
        infoPanel={infoPanel}
        isModalOpen={isDialogOpen}
        isButtonCancel={false}
        dialogType={dialogType}
        liftingActionConfirm={liftingActionConfirm}
        handleClose={() => setIsDialogOpen(false)}
      />
      <MainAppBar
        liftingSearch={liftingSearch}
        liftingSesionBar={() => setIsLogged(false)}
        liftingStateRow={false}
        visibleStateRows={visibleStateRows}
        filterString={filterString}
        dateFilter={false}
        setDateFilter={false}
        rutas={rutas}
        setFilterRoutes={(filter) => setFiltroRutas(filter)}
        filterRoutes={filtroRutas}
        location="En curso"
      />
      <Alert open={open} setOpen={setOpen} msg={msg} alertType={alertType} />
      <MainTable
        currentTracks={currentTracks}
        checadas={checadas}
        unidades={unidadesArray}
        pointsRoute={pointsRoute}
        liftingAction={liftingAction}
        visibleStateRows={visibleStateRows}
        typeTable="mainScreen"
        filterString={filterString}
        filtroRutas={filtroRutas}
      />
    </Container>
  );
};

export default MainScreen;
