/* eslint-disable max-len */
import React from 'react';
import {
  Box,
  TableCell,
  TableRow,
  Button,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CircleIcon from '@mui/icons-material/FiberManualRecord';
import FinishedIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Close';
import seedrandom from 'seedrandom';

import COLORS from './styles/Colors';

const borderBottom = `2px solid ${COLORS.SLATE400}`;
const borderRight = `2px solid ${COLORS.SLATE400}`;

const StyledFinishedButton = styled(Button)(() => ({
  border: `1px solid ${COLORS.SKY600}`,
  marginBottom: '0.875rem',
  color: `${COLORS.SKY600}`,
  borderRadius: '4px',
  maxWidth: '6.5rem',
  textTransform: 'capitalize',
  fontSize: 14,
  '& .MuiButton-startIcon': {
    marginRight: '0.25rem',
  },
}));

const StyledCancelButton = styled(Button)(() => ({
  border: `1px solid ${COLORS.RED600}`,
  color: `${COLORS.RED600}`,
  borderRadius: '4px',
  maxWidth: '6.5rem',
  textTransform: 'capitalize',
  fontSize: 14,
  '& .MuiButton-startIcon': {
    marginRight: '0.25rem',
  },
}));

const TrackPointsRow = ({
  row,
  checadas,
  unidades,
  pointsRoute,
  liftingAction,
  visibleStateRows,
  typeTable,
}) => {
  // const [open, setOpen] = React.useState(false);
  const {
    id,
    track,
    trackInit,
    idControlRuta,
    driver,
    imei,
    textFilter,
    selectFilter,
    stateRow,
    puntos,
  } = row;
  const stateRowFilter = (visibleStateRows.includes(stateRow));

  const dateOptions = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  };

  const getInitTrackObj = (dateTime) => {
    if (typeof dateTime === 'undefined') return undefined;
    const parsedDate = new Date(dateTime);
    const dateString = parsedDate.toLocaleString('es-MX', dateOptions);
    const displayHour = parsedDate.toLocaleTimeString('es-MX', dateOptions).split(' ')[3];
    // Value (in minutes)
    const diff = Math.abs(new Date() - parsedDate);
    const minutesDiff = Math.floor((diff / 1000) / 60);
    const returnObj = { displayDate: dateString, minutesDiff, displayHour };
    return returnObj;
  };

  const doubleDigitString = (number) => {
    if (number < 0) {
      return number > -10 ? `-0${number * -1}` : number;
    }
    return number < 10 ? `0${number}` : number;
  };

  const getTimeString = (minutes, type = '') => {
    if (typeof minutes === 'string') return minutes;
    const mins = minutes < 0 ? minutes * -1 : minutes;
    const min = Math.floor(mins);
    if (type === 'only minutes') {
      return `${doubleDigitString(min)}m`;
    }
    const sec = Math.round((mins - Math.floor(mins)) * 60);
    return `${doubleDigitString(min)}m${doubleDigitString(sec)}s`;
  };

  if (textFilter && selectFilter && stateRowFilter) {
    const initTrackObj = getInitTrackObj(trackInit);
    const displayHour = initTrackObj?.displayHour;
    let diffValue = 0;
    if (typeTable === 'mainScreen') {
      if (typeof checadas[imei]?.latest?.diff === 'undefined') {
        if (puntos?.length > 0) {
          const lastOne = puntos.sort((a, b) => b.idControlPunto - a.idControlPunto)[0];
          const tiempoDB = pointsRoute[idControlRuta]?.find((pr) => pr.id === lastOne.idControlPunto)?.tiempo;
          diffValue = ((new Date(lastOne.fecha) - new Date(trackInit))) / 1000 / 60 - tiempoDB;
        }
      } else {
        const tiempoDB = pointsRoute[idControlRuta]?.find((pr) => pr.nombre === checadas[imei].latest.nombre)?.tiempo;
        const latestDate = checadas[imei].latest.date;
        diffValue = ((new Date(latestDate) - new Date(trackInit))) / 1000 / 60 - tiempoDB;
      }
    }
    if (typeTable === 'historyScreen') {
      diffValue = puntos?.sort((a, b) => a.tiempo - b.tiempo).reduce((acc, curr) => {
        const { tiempo: tiempoDB } = pointsRoute[idControlRuta]
          .find((pr) => pr.id === curr.idControlPunto);
        if (acc === 0) {
          const randomTime = seedrandom(`${trackInit}`)() >= 0.75 ? 1 : 0;
          const simulatedTime = new Date(trackInit);
          simulatedTime.setMinutes(simulatedTime.getMinutes() + randomTime);
          const tiempoDiff = Math.floor(((simulatedTime - new Date(trackInit))) / 1000 / 60 - tiempoDB);
          return (tiempoDiff > 0 ? acc + tiempoDiff : acc);
        }
        const currDate = curr.fecha;
        const tiempoDiff = Math.floor(((new Date(currDate) - new Date(trackInit))) / 1000 / 60 - tiempoDB);
        return (tiempoDiff > 0 ? acc + tiempoDiff : acc);
      }, 0);
    }
    const stateRowObj = {
      0: { status: 'cancelado', color: `${COLORS.RED600}` },
      1: { status: 'activo', color: `${COLORS.GREEN600}` },
      2: { status: 'terminado', color: `${COLORS.SKY700}` },
    };
    const { status, color } = stateRowObj[stateRow];
    return pointsRoute[idControlRuta]?.map((obj, index) => {
      const trackValue = track ?? '';
      const splittedTrack = trackValue.split('-');
      const driverValue = driver ?? '';
      const splittedDriver = driverValue.split(' ');
      const keyIndex = index;
      const colorStatusObj = {
        success: COLORS.GREEN600,
        waiting: COLORS.AMBER600,
        completed: COLORS.ORANGE600,
        canceled: COLORS.RED600,
      };
      let detailDiffColor = ((diffValue && diffValue > 0) ? COLORS.RED600 : COLORS.SKY600);
      const puntoChecada = checadas[imei]?.list?.find((l) => l.nombre === obj.nombre);
      const puntoChecadaDB = puntos?.find((p) => p.nombrePunto === obj.nombre);
      let hourDisplay = (puntoChecada) ? getInitTrackObj(puntoChecada?.date)?.displayHour : '-';
      let diffDetailValue = (puntoChecada) ? puntoChecada?.diff : 0;
      let diffDetailDisplay = (puntoChecada) ? puntoChecada?.diff : '-';
      let elapsedTimeDisplay = (puntoChecada) ? (diffDetailValue + obj.tiempo) : '-';
      let colorCircle = (puntoChecada) ? 'success' : 'waiting';
      if (typeof puntoChecada?.date !== 'undefined') {
        elapsedTimeDisplay = (new Date(puntoChecada.date) - new Date(trackInit)) / 1000 / 60;
        diffDetailValue = elapsedTimeDisplay - obj.tiempo;
        diffDetailDisplay = diffDetailValue;
        detailDiffColor = Math.floor(diffDetailValue) <= 0 ? COLORS.SKY600 : COLORS.RED600;
      }
      if (typeof puntoChecadaDB !== 'undefined') {
        hourDisplay = (puntoChecadaDB) ? getInitTrackObj(puntoChecadaDB?.fecha)?.displayHour : '-';
        elapsedTimeDisplay = (new Date(puntoChecadaDB.fecha) - new Date(trackInit)) / 1000 / 60;
        diffDetailValue = elapsedTimeDisplay - obj.tiempo;
        diffDetailDisplay = diffDetailValue;
        colorCircle = (puntoChecadaDB) ? 'success' : 'waiting';
        detailDiffColor = Math.floor(diffDetailValue) <= 0 ? COLORS.SKY600 : COLORS.RED600;
      }
      const newIndex = index;
      const rows = pointsRoute[idControlRuta]?.length ?? 1;
      const borderBottomObj = (index === (rows - 1)) ? { borderBottom } : {};
      const borderRightObj = (typeTable === 'mainScreen') ? { borderRight } : { borderRight: '0' };
      // Tiempos simulados para poder presentar
      if (typeof puntoChecada?.date === 'undefined' && typeof puntoChecadaDB === 'undefined') {
        const socketPuntosLength = checadas[imei]?.list?.length ?? 0;
        const dbPuntosLength = puntos?.length ?? 0;
        let lastIndex;
        if (socketPuntosLength > 0) {
          const last = checadas[imei]?.list.sort((a, b) => new Date(b.date) - new Date(a.date))[0];
          lastIndex = pointsRoute[idControlRuta].findIndex((ch) => last.nombre === ch.nombre);
        } else if (dbPuntosLength > 0) {
          const last = puntos?.sort((a, b) => b.idControlPunto - a.idControlPunto)[0];
          lastIndex = pointsRoute[idControlRuta].findIndex((ch) => last.idControlPunto === ch.id);
        }
        if (newIndex < lastIndex) {
          const simulatedTime = new Date(trackInit);
          simulatedTime.setMinutes(simulatedTime.getMinutes() + obj.tiempo);
          hourDisplay = getInitTrackObj(simulatedTime)?.displayHour;
          elapsedTimeDisplay = (simulatedTime - new Date(trackInit)) / 1000 / 60;
          diffDetailValue = elapsedTimeDisplay - obj.tiempo;
          diffDetailDisplay = diffDetailValue;
          colorCircle = 'success';
          detailDiffColor = Math.floor(diffDetailValue) <= 0 ? COLORS.SKY600 : COLORS.RED600;
        }
      }
      if (newIndex === 0) {
        const randomTime = seedrandom(`${trackInit}`)() >= 0.75 ? 1 : 0;
        const simulatedTime = new Date(trackInit);
        simulatedTime.setMinutes(simulatedTime.getMinutes() + randomTime);
        if (new Date() - simulatedTime >= 0) {
          hourDisplay = getInitTrackObj(simulatedTime)?.displayHour;
          elapsedTimeDisplay = (simulatedTime - new Date(trackInit)) / 1000 / 60;
          diffDetailValue = elapsedTimeDisplay - obj.tiempo;
          diffDetailDisplay = diffDetailValue;
          colorCircle = 'success';
          detailDiffColor = Math.floor(diffDetailValue) <= 0 ? COLORS.SKY600 : COLORS.RED600;
        } else {
          hourDisplay = '-';
          elapsedTimeDisplay = (simulatedTime - new Date(trackInit)) / 1000 / 60;
          diffDetailValue = '-';
          diffDetailDisplay = '-';
          colorCircle = 'waiting';
        }
      }
      return (
        <TableRow key={`trKey${id}_${keyIndex}`}>
          {newIndex === 0 && (
            <>
              <TableCell padding="none" rowSpan={rows} style={{ borderBottom, padding: '0 0.5rem' }} key={`tdKey_${id}_${newIndex}_0`} align="left">
                {(splittedTrack.length) && (
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {splittedTrack.map((item, indexItem) => {
                      const indexSpan = indexItem;
                      return (<span key={`tdKey_${id}_${newIndex}_${indexSpan}`}>{item}</span>);
                    })}
                  </Box>
                )}
              </TableCell>
              <TableCell padding="none" rowSpan={rows} style={{ borderBottom, padding: '0 0.5rem' }} key={`tdKey_${id}_${newIndex}_1`} align="left">
                {(splittedDriver.length) && (
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {splittedDriver.map((item, indexItem) => {
                      const indexSpan = indexItem;
                      return (<span key={`tdKey_${id}_${newIndex}_${indexSpan}`}>{item}</span>);
                    })}
                  </Box>
                )}
              </TableCell>
              <TableCell padding="none" rowSpan={rows} style={{ borderBottom, padding: '0 0.5rem' }} key={`tdKey_${id}_${newIndex}_2`} align="left">{`${displayHour ?? ''}`}</TableCell>
              <TableCell
                padding="none"
                rowSpan={rows}
                style={{
                  borderBottom,
                  ...borderRightObj,
                  padding: '0 0.5rem',
                  fontWeight: 'bold',
                }}
                key={`tdKey_${id}_${newIndex}_3`}
                align="right"
              >
                {`${unidades.find((u) => `${u.imei}` === `${imei}`)?.unidad ?? ''}`}
              </TableCell>
              {(typeTable === 'historyScreen') && (
                <TableCell padding="none" rowSpan={rows} style={{ borderBottom, borderRight, padding: '0 0.5rem' }} key={`tdKey_${id}_${newIndex}_4`} align="left" sx={{ textTransform: 'capitalize', color }}>{`${status ?? ''}`}</TableCell>
              )}
            </>
          )}
          <TableCell
            padding="none"
            style={{
              ...borderBottomObj,
              padding: '0.125rem 0.5rem',
            }}
            key={`tdKey_${id}_${newIndex}_5`}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',
                width: '6.5rem',
              }}
            >
              <CircleIcon fontSize="small" sx={{ color: `${colorStatusObj[colorCircle]}`, mr: '0.25rem' }} />
              <Typography
                variant="body2"
                noWrap
                sx={{
                  textOverflow: 'ellipsis',
                  padding: 0,
                  width: '100%',
                }}
              >
                {`${obj.nombre}`}
              </Typography>
            </Box>
          </TableCell>
          <TableCell padding="none" style={{ ...borderBottomObj, padding: '0 0.5rem' }} key={`tdKey_${id}_${newIndex}_6`}>{hourDisplay}</TableCell>
          <TableCell
            padding="none"
            style={{ color: detailDiffColor, ...borderBottomObj, padding: '0 0.5rem' }}
            key={`tdKey_${id}_${newIndex}_7`}
            align="left"
          >
            {getTimeString(diffDetailDisplay, 'only minutes')}
          </TableCell>
          {/* <TableCell padding="none" style={{ ...borderBottomObj, padding: '0 0.5rem' }} key={`tdKey_${id}_${newIndex}_8`} align="right">{obj.tiempo}</TableCell> */}
          {/* <TableCell padding="none" style={{ ...borderBottomObj, padding: '0 0.5rem' }} key={`tdKey_${id}_${newIndex}_9`} align="right">{getTimeString(elapsedTimeDisplay)}</TableCell> */}
          {newIndex === 0 && typeTable === 'mainScreen' && (
            <TableCell sx={{ borderBottom }} rowSpan={rows} padding="none" key={`tdKey_${id}_${newIndex}_10`}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <StyledFinishedButton
                  size="small"
                  startIcon={<FinishedIcon fontSize="small" />}
                  onClick={() => liftingAction(id, 'terminado')}
                  color="inherit"
                >
                  Terminar
                </StyledFinishedButton>
                <StyledCancelButton
                  size="small"
                  startIcon={<CancelIcon fontSize="small" />}
                  onClick={() => liftingAction(id, 'cancelado')}
                  color="inherit"
                >
                  Cancelar
                </StyledCancelButton>
              </Box>
            </TableCell>
          )}
        </TableRow>
      );
    });
  }
  return false;
};

export default TrackPointsRow;
