import React, { useState, useEffect } from 'react';
import { Routes, Route, HashRouter } from 'react-router-dom';

import Login from './Login';
import MainScreen from './MainScreen';
import HistoryScreen from './HistoryScreen';
import LastCheckpointScreen from './LastCheckpointScreen';

const useStorageState = (key, initialState) => {
  const [value, setValue] = useState(
    localStorage.getItem(key) || initialState,
  );
  useEffect(() => {
    localStorage.setItem(key, value);
  }, [value, key]);
  return [value, setValue];
};

const App = () => {
  const [id, setId] = useStorageState('id', 0);
  const [token, setToken] = useStorageState('token', '');

  const liftingSession = (idProp, tokenProp) => {
    setId(0);
    setToken('');
    localStorage.setItem('id', idProp);
    localStorage.setItem('token', tokenProp);
  };

  return (
    <HashRouter>
      <Routes>
        <Route
          path="/"
          element={<Login setId={setId} setToken={setToken} id={id} token={token} />}
        />
        <Route
          path="/main"
          element={<MainScreen id={id} token={token} liftingSession={liftingSession} />}
        />
        <Route
          path="/history"
          element={<HistoryScreen id={id} token={token} liftingSession={liftingSession} />}
        />
        <Route
          path="/lastcheckpoint"
          element={<LastCheckpointScreen id={id} token={token} liftingSession={liftingSession} />}
        />
      </Routes>
    </HashRouter>
  );
};

export default App;
