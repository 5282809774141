import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import COLORS from '../styles/Colors';

const StyledTextField = styled(TextField)(({ theme }) => ({
  height: '100%',
  '& .MuiInputBase-root': {
    height: '100%',
  },
  '& .MuiInputBase-input': {
    color: COLORS.SLATE300,
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
  '& .MuiFormLabel-root': { color: COLORS.GREEN500 },
  '& .MuiFormControl-root': { height: '100%' },
  '& .MuiOutlinedInput-notchedOutline': {
    border: `2px solid ${COLORS.GREEN500} !important`,
    borderRightWidth: '2px !important',
    borderRadius: '6px',
    [theme.breakpoints.up('md')]: {
      borderRightWidth: '1px !important',
      borderRadius: '6px 0 0 6px',
    },
  },
  '& .Mui-focused': {
    '&.MuiFormLabel-root': { color: `${COLORS.CYAN500}` },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `2px solid ${COLORS.CYAN500} !important`,
      borderRightWidth: '2px !important',
      borderRadius: '6px',
      [theme.breakpoints.up('md')]: {
        borderRadius: '6px 0 0 6px',
        borderRightColor: `${COLORS.CYAN500} !important`,
      },
    },
  },
  '& svg': { color: COLORS.SKY200 },
}));

const BasicDateTimePicker = ({
  dateFilter,
  setDateFilter,
}) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DatePicker
      views={['year', 'month', 'day']}
      label="Fecha de consulta"
      value={dateFilter}
      onChange={(newValue) => setDateFilter(newValue.format('YYYY-MM-DD'))}
      renderInput={({
        InputProps,
        className,
        disabled,
        error,
        inputRef,
        ref,
        label,
        inputProps,
      }) => (
        <StyledTextField
          fullWidth
          InputProps={{ ...InputProps, ...inputProps }}
          variant="outlined"
          className={className}
          disabled={disabled}
          error={error}
          inputRef={inputRef}
          label={label}
          ref={ref}
        />
      )}
    />
  </LocalizationProvider>
);

export default BasicDateTimePicker;
