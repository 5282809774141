import React, { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  ListItemButton,
  ListItemText,
  ListItem,
  Checkbox,
  TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TrackFilterOffIcon from '@mui/icons-material/RemoveRoadOutlined';
import TrackFilterOnIcon from '@mui/icons-material/EditRoadOutlined';
import StateRowFilterOnIcon from '@mui/icons-material/FilterListOutlined';
import StateRowFilterOffIcon from '@mui/icons-material/FilterListOffOutlined';
import LogOutIcon from '@mui/icons-material/ExitToApp';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import DateField from './basics/DatePicker';
import MainStyles from './styles/GeneralStyles';
import MainMenu from './basics/Drawer';

import COLORS from './styles/Colors';

const { headerBox } = MainStyles;

const DivHeader = styled('div')(() => ({ ...headerBox }));

const StyledSearchInput = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  height: 48,
  marginRight: 0,
  marginLeft: 0,
  width: '100%',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1, 0, 1),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledTextField = styled(TextField)(() => ({
  width: '100%',
  height: '100%',
  paddingBottom: 0,
  '& .Mui-focused': {
    '&.MuiInputBase-root': {
      borderColor: `${COLORS.CYAN500}`,
      borderWidth: '2px',
      borderRightWidth: '2px !important',
      borderLeftWidth: '2px !important',
    },
  },
}));

const StyledAppBar = styled(AppBar)(() => ({
  backgroundColor: COLORS.SLATE900,
  color: COLORS.SLATE50,
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  padding: '0.5rem',
  [theme.breakpoints.up('sm')]: {
    padding: '0.5rem 0.5rem 0',
  },
}));

// Filter icons
const StyledIconTrackFilterOn = styled(TrackFilterOnIcon)(() => ({ color: `${COLORS.GREEN400}` }));
const StyledIconTrackFilterOff = styled(TrackFilterOffIcon)(() => ({ color: `${COLORS.ZINC400}` }));

const StyledIconStateRowFilterOn = styled(StateRowFilterOnIcon)(() => ({ color: `${COLORS.GREEN400}` }));
const StyledIconStateRowFilterOff = styled(StateRowFilterOffIcon)(() => ({ color: `${COLORS.ZINC400}` }));

const SearchAppBar = ({
  liftingSearch,
  liftingSesionBar,
  liftingStateRow,
  visibleStateRows,
  filterString,
  dateFilter,
  setDateFilter,
  rutas,
  setFilterRoutes,
  filterRoutes,
  location,
}) => {
  const [trackAnchorEl, setTrackAnchorEl] = useState(null);
  const [stateRowAnchorEl, setStateRowAnchorEl] = useState(null);

  // Tracks filter methods
  const handleMobileTracksClose = () => setTrackAnchorEl(null);
  const isMobileTrackFilterOpen = Boolean(trackAnchorEl);
  const handleFilterTrackOpen = (event) => setTrackAnchorEl(event.currentTarget);
  const [trackFilterActive, setTrackFilterActive] = useState(false);

  // State Row filter methods
  const handleMobileStateRowClose = () => setStateRowAnchorEl(null);
  const isMobileStateRowFilterOpen = Boolean(stateRowAnchorEl);
  const handleFilterStateRowOpen = (event) => setStateRowAnchorEl(event.currentTarget);
  const [stateRowFilterActive, setStateRowFilterActive] = useState(true);

  const trackIcon = (trackFilterActive)
    ? <StyledIconTrackFilterOn /> : <StyledIconTrackFilterOff />;
  const borderTrackIcon = (trackFilterActive) ? `2px solid ${COLORS.GREEN400}` : `1px solid ${COLORS.SLATE400}`;
  const stateRowIcon = (stateRowFilterActive)
    ? <StyledIconStateRowFilterOn /> : <StyledIconStateRowFilterOff />;
  const borderStateRowIcon = (stateRowFilterActive) ? `2px solid ${COLORS.GREEN400}` : `1px solid ${COLORS.SLATE400}`;

  const addRemoveToArray = (array, currentValue, isRemove) => {
    const index = array.indexOf(currentValue);
    const arrayToReturn = [...array];

    if (index === -1) {
      arrayToReturn.push(currentValue);
    } else if (isRemove) {
      arrayToReturn.splice(index, 1);
    }
    return arrayToReturn;
  };

  const handleToggle = (value) => () => {
    const newFilter = addRemoveToArray(filterRoutes, value, true);
    setTrackFilterActive(true);
    if (newFilter.length === rutas.length) {
      setTrackFilterActive(false);
    }
    setFilterRoutes(newFilter);
  };

  const handleToggleStateRow = (value) => () => {
    const arrayStateRow = addRemoveToArray(visibleStateRows, value, true);
    setStateRowFilterActive(true);
    if (arrayStateRow.length === 3) {
      setStateRowFilterActive(false);
    }
    liftingStateRow(arrayStateRow);
  };

  const trackMenuId = 'track-menu-mobile';
  const tracksFilter = (
    <Box sx={{ width: 240 }}>
      <Menu
        anchorEl={trackAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={trackMenuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMobileTrackFilterOpen}
        onClose={handleMobileTracksClose}
        sx={{ p: 0 }}
      >
        {rutas?.map((ruta) => {
          const labelId = `checkbox-list-secondary-label-${ruta.id}`;
          return (
            <ListItem
              key={`ruta-${ruta.id}`}
              secondaryAction={(
                <Checkbox
                  edge="end"
                  onChange={handleToggle(ruta.id)}
                  checked={filterRoutes.includes(ruta.id)}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
            )}
              disablePadding
            >
              <ListItemButton sx={{ py: 0.5 }}>
                <ListItemText id={labelId} primary={ruta.nombre} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </Menu>
    </Box>
  );

  const stateRowMenuId = 'state-row-menu-mobile';
  const radius = '.375rem';
  const radiusLeft = '.375rem 0 0 .375rem';
  const radiusRight = '0 .375rem .375rem 0';
  const xsLocation = (dateFilter) ? 7 : 12;
  const mdGridSearchBar = (dateFilter) ? 6 : 8;
  const lgGridSearchBar = (dateFilter) ? 5 : 7;
  const radiusPanelSearch = (dateFilter) ? { xs: radius, md: radiusRight } : radius;
  const radiusSearchContainer = (dateFilter) ? { xs: radiusLeft, md: 0 } : radiusLeft;
  const radiusFilterTracks = (location === 'Historial') ? 0 : radiusRight;
  const radiusTextFieldLeft = (dateFilter) ? { xs: radius, md: 0 } : radius;
  const textFieldBorderColor = (!filterString) ? `${COLORS.SLATE400}` : `${COLORS.GREEN500}`;
  const textFieldBorderWidth = (!filterString) ? '1px' : '2px';
  const textFieldBorderLeftWidth = (!filterString || dateFilter) ? '1px' : '2px';
  return (
    <DivHeader>
      <StyledAppBar position="static">
        <StyledToolbar>
          <Grid container style={{ width: '100%' }}>
            <Grid xs={xsLocation} md={4} lg={5} sx={{ mb: { xs: 1.5, md: 0 } }}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                width: '100%',
              }}
              >
                <MainMenu location={location} />
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                >
                  {location}
                </Typography>
              </Box>
            </Grid>
            {dateFilter && (
              <Grid xs={5} md={2} sx={{ mb: { xs: 1.5, md: 0 } }}>
                <Box sx={{
                  ml: 0,
                  mr: 0,
                  my: 0,
                  width: '100%',
                  height: '100%',
                  '& svg': { m: 0 },
                  '& hr': { mx: 0.5 },
                }}
                >
                  <DateField dateFilter={dateFilter} setDateFilter={setDateFilter} />
                </Box>
              </Grid>
            )}
            <Grid
              xs={12}
              md={mdGridSearchBar}
              lg={lgGridSearchBar}
              sx={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                mt: { xs: 0, sm: 1, md: 0 },
                mb: { xs: 0, sm: 2, md: 0 },
              }}
            >
              <Box sx={{
                ml: { xs: 1, sm: 0 },
                mr: 1.5,
                my: 0,
                flexGrow: 1,
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                width: 'fit-content',
                borderRadius: radiusPanelSearch,
                '& svg': { m: 0.5 },
                '& hr': { mx: 0.5 },
              }}
              >
                <StyledSearchInput sx={{ borderRadius: radiusSearchContainer }}>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledTextField
                    variant="filled"
                    InputProps={{
                      disableUnderline: true,
                      inputProps: {
                        'aria-label': 'search',
                        style: {
                          padding: '0 1rem',
                          paddingLeft: '3rem',
                          height: '100%',
                        },
                      },
                      sx: {
                        borderTopLeftRadius: radiusTextFieldLeft,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: radiusTextFieldLeft,
                        height: '100%',
                        color: `${COLORS.SLATE200}`,
                        borderColor: `${textFieldBorderColor}`,
                        borderStyle: 'solid',
                        borderTopWidth: textFieldBorderWidth,
                        borderBottomWidth: textFieldBorderWidth,
                        borderLeftWidth: textFieldBorderLeftWidth,
                        borderRightWidth: '1px',
                        margin: 0,
                      },
                    }}
                    placeholder="Buscar…"
                    value={filterString}
                    onChange={(e) => liftingSearch(e.target.value)}
                    margin="none"
                    sx={{
                      '& .Mui-focused': {
                        '&.MuiInputBase-root': {
                          borderLeftWidth: textFieldBorderLeftWidth,
                        },
                      },
                    }}
                  />
                </StyledSearchInput>
                <IconButton
                  size="small"
                  aria-label="filter"
                  aria-controls={trackMenuId}
                  aria-haspopup="true"
                  onClick={handleFilterTrackOpen}
                  color="inherit"
                  sx={{
                    height: 48,
                    border: borderTrackIcon,
                    borderRadius: radiusFilterTracks,
                  }}
                >
                  {trackIcon}
                </IconButton>
                {location === 'Historial' && (
                  <IconButton
                    size="small"
                    aria-label="filter"
                    aria-controls={stateRowMenuId}
                    aria-haspopup="true"
                    onClick={handleFilterStateRowOpen}
                    color="inherit"
                    sx={{
                      height: 48,
                      border: borderStateRowIcon,
                      borderRadius: radiusRight,
                      borderLeftWidth: '1px !important',
                    }}
                  >
                    {stateRowIcon}
                  </IconButton>
                )}
              </Box>
              <IconButton
                size="small"
                onClick={() => liftingSesionBar(false)}
                color="inherit"
                sx={{
                  height: 48,
                  ml: { xs: 0, sm: 1, md: 2 },
                  mr: { xs: 0, md: 2 },
                  border: `2px solid ${COLORS.RED700}`,
                  borderRadius: radius,
                }}
              >
                <LogOutIcon color="inherit" sx={{ m: 0.5, color: `${COLORS.RED700}` }} />
              </IconButton>
            </Grid>
          </Grid>
        </StyledToolbar>
      </StyledAppBar>
      {tracksFilter}
      {(location === 'Historial') && (
        <Box sx={{ width: '11rem' }}>
          <Menu
            anchorEl={stateRowAnchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            id={stateRowMenuId}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={isMobileStateRowFilterOpen}
            onClose={handleMobileStateRowClose}
            sx={{ p: 0 }}
          >
            <ListItem
              key="item-activo"
              secondaryAction={(
                <Checkbox
                  edge="end"
                  onChange={handleToggleStateRow(1)}
                  checked={visibleStateRows.includes(1)}
                  inputProps={{ 'aria-labelledby': 'checklist-state-row-1' }}
                />
            )}
              disablePadding
            >
              <ListItemButton sx={{ py: 0.5 }}>
                <ListItemText id="checklist-state-row-1" primary="Activo" />
              </ListItemButton>
            </ListItem>
            <ListItem
              key="item-cancelado"
              secondaryAction={(
                <Checkbox
                  edge="end"
                  onChange={handleToggleStateRow(0)}
                  checked={visibleStateRows.includes(0)}
                  inputProps={{ 'aria-labelledby': 'checklist-state-row-0' }}
                />
            )}
              disablePadding
            >
              <ListItemButton sx={{ py: 0.5 }}>
                <ListItemText id="checklist-state-row-0" primary="Cancelado" />
              </ListItemButton>
            </ListItem>
            <ListItem
              key="item-terminado"
              secondaryAction={(
                <Checkbox
                  edge="end"
                  onChange={handleToggleStateRow(2)}
                  checked={visibleStateRows.includes(2)}
                  inputProps={{ 'aria-labelledby': 'checklist-state-row-2' }}
                />
            )}
              disablePadding
            >
              <ListItemButton sx={{ py: 0.5 }}>
                <ListItemText id="checklist-state-row-2" primary="Terminado" />
              </ListItemButton>
            </ListItem>
          </Menu>
        </Box>
      )}
    </DivHeader>
  );
};

export default SearchAppBar;
