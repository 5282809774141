/** @jsxImportSource @emotion/react */
import { Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import MainAppBar from './Components/AppBar';
import MainTable from './Components/CollapsibleTable';
import MainStyles from './Components/styles/GeneralStyles';
import Alert from './Components/Alert';

import SECRETS from './secrets';

const { initBox } = MainStyles;

const twoDigits = (num) => {
  const numTwoDigits = (num <= 9) ? `0${num}` : `${num}`;
  return numTwoDigits;
};

const HistoryScreen = ({ id, token, liftingSession }) => {
  const [isLogged, setIsLogged] = useState(true);
  const [unidadesArray, setUnidadesArray] = useState([]);
  const [pointsRoute, setPointsRoute] = useState({});
  const [currentTracks, setCurrentTracks] = useState([]);
  const [rutas, setRutas] = useState([]);
  const [filterString, setFilterString] = useState('');
  const [filtroRutas, setFiltroRutas] = useState([]);
  const [visibleStateRows, setVisibleStateRows] = useState([2]);
  // Alert
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const [alertType, setAlertType] = useState('');
  const [dateFilter, setDateFilter] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (id <= 0 && token.length === 0) {
      setIsLogged(false);
    }
  });

  useEffect(() => {
    const date = new Date();
    setDateFilter(`${date.getFullYear()}-${twoDigits(date.getMonth() + 1)}-${twoDigits(date.getDate())}`);
  }, []);

  useEffect(() => {
    axios.defaults.headers.common.Authorization = `bearer ${token}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .get('/controlruta')
      .then((response) => {
        setRutas(response.data);
        const ObjToPush = {};
        const arrayIds = [];
        response.data.forEach(({ id: idControlRuta }) => {
          arrayIds.push(idControlRuta);
          axios.defaults.headers.common.Authorization = `bearer ${token}`;
          axios.defaults.baseURL = SECRETS.SERVERURL;
          axios
            .get(`/controlpunto/byRuta/${idControlRuta}`)
            .then((resp) => {
              const newObjWithPoint = {
                [idControlRuta]: resp.data || [],
              };
              Object.assign(ObjToPush, newObjWithPoint);
              setPointsRoute(ObjToPush);
            })
            .catch((e) => {
              console.error(e);
            });
        });
        setFiltroRutas(arrayIds);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  useEffect(() => {
    if (!isLogged) {
      liftingSession(0, '');
      navigate('/');
    }
  }, [isLogged]);

  useEffect(() => {
    if (dateFilter !== '') {
      const getStateRow = (cancel, finished) => {
        if (cancel) { return 'cancelado'; }
        if (finished) { return 'terminado'; }
        return 'activo';
      };
      setAlertType('info');
      setMsg('Cargando...');
      axios.defaults.headers.common.Authorization = `bearer ${token}`;
      axios.defaults.baseURL = SECRETS.SERVERURL;
      axios
        .get('/unidades/organizacion')
        .then((response) => {
          const unitsArray = response.data;
          const unitsSorted = unitsArray.sort((a, b) => a.unidad.localeCompare(b.unidad));
          setUnidadesArray(unitsSorted);

          axios.defaults.headers.common.Authorization = `bearer ${token}`;
          axios.defaults.baseURL = SECRETS.SERVERURL;
          axios
            .post('/controlrecorrido', { fecha: dateFilter })
            .then((resp) => {
              const tableArray = resp.data.map((track) => {
                const {
                  nombreRuta,
                  inicio,
                  nombreOperador,
                  cancelado,
                  terminado,
                  puntos,
                } = track;
                const stateRowObj = {
                  cancelado: 0,
                  activo: 1,
                  terminado: 2,
                };
                const stateRow = stateRowObj[getStateRow(cancelado, terminado)];
                return ({
                  ...track,
                  puntos,
                  editable: false,
                  textFilter: true,
                  track: nombreRuta,
                  trackInit: inicio,
                  driver: nombreOperador,
                  lastPoint: '-',
                  diff: '-',
                  points: [],
                  selectFilter: true,
                  stateRow,
                });
              }).sort((a, b) => {
                if (a.trackInit > b.trackInit) { return -1; }
                if (a.trackInit < b.trackInit) { return 1; }
                return 0;
              });
              setCurrentTracks(tableArray);
              setMsg('Tabla cargada');
              setAlertType('success');
              setOpen(true);
            })
            .catch((e) => {
              console.error(e);
            });
        });
    }
  }, [dateFilter]);

  const liftingStateRow = (arrayStateRows) => setVisibleStateRows(arrayStateRows);

  const liftingAction = (recorridoId, action) => {
    // setLoading(true);
    const getTZUTCDate = () => {
      const now = new Date();
      const UTCYear = now.getUTCFullYear();
      const UTCMonth = twoDigits(now.getUTCMonth() + 1);
      const UTCDay = twoDigits(now.getUTCDate());
      const UTCHour = twoDigits(now.getUTCHours());
      const UTCMinutes = twoDigits(now.getUTCMinutes());
      return `${UTCYear}-${UTCMonth}-${UTCDay}T${UTCHour}:${UTCMinutes}:00Z`;
    };
    axios.defaults.headers.common.Authorization = `bearer ${token}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    const actionObj = {
      cancelado: { [action]: true },
      terminado: { [action]: true, fin: getTZUTCDate() },
    };
    const actionObjSelected = actionObj[action] || {};
    axios
      .patch(`/controlrecorrido/${recorridoId}`, actionObjSelected)
      .then(() => {
        const clone = [...currentTracks];
        const index = clone.findIndex((c) => c.id === recorridoId);
        if (index >= 0) {
          const stateRowObj = { cancelado: 0, terminado: 2 };
          clone[index].stateRow = stateRowObj[action];
          // setLoading(false);
          // clone.splice(index, 1);
          setCurrentTracks(clone);
          const msgDisplayObj = {
            terminado: 'Recorrido finalizado',
            cancelado: 'Recorrido cancelado',
          };
          const msgDisplay = msgDisplayObj[action] ?? 'Se han actualizado los datos';
          setMsg(msgDisplay);
          setAlertType('success');
          setOpen(true);
        }
      })
      .catch((e) => {
        // setLoading(false);
        setMsg('Sin conexión a internet');
        setAlertType('error');
        console.error(e);
        if (e.response.status === 401) {
          // setId(0);
          // setToken('');
        }
      });
  };

  const liftingSearch = (searchValue) => setFilterString(searchValue);

  return (
    <Container
      css={initBox}
      maxWidth={false}
      disableGutters
    >
      <MainAppBar
        liftingSearch={liftingSearch}
        liftingSesionBar={() => setIsLogged(false)}
        liftingStateRow={liftingStateRow}
        visibleStateRows={visibleStateRows}
        filterString={filterString}
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
        rutas={rutas}
        setFilterRoutes={(filter) => setFiltroRutas(filter)}
        filterRoutes={filtroRutas}
        location="Historial"
      />
      <Alert open={open} setOpen={setOpen} msg={msg} alertType={alertType} />
      <MainTable
        currentTracks={currentTracks}
        checadas={[]}
        unidades={unidadesArray}
        pointsRoute={pointsRoute}
        liftingAction={liftingAction}
        visibleStateRows={visibleStateRows}
        typeTable="historyScreen"
        filterString={filterString}
        filtroRutas={filtroRutas}
      />
    </Container>
  );
};

export default HistoryScreen;
