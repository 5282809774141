import React, { useState } from 'react';
import Box from '@mui/material/Box';
import {
  Drawer,
  // Button,
  List,
  IconButton,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import LiveTrackingIcon from '@mui/icons-material/AirportShuttle';
import HistoryIcon from '@mui/icons-material/AccessTime';

import COLORS from '../styles/Colors';

const TemporaryDrawer = ({ location }) => {
  const [state, setState] = useState(false);

  const toggleDrawer = (open) => (e) => {
    if (e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift')) {
      return;
    }

    setState(open);
  };

  const style = (condition) => {
    if (condition) {
      return {
        color: COLORS.SLATE50,
        backgroundColor: COLORS.SLATE700,
      };
    }
    return {};
  };

  return (
    <>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="open drawer"
        onClick={toggleDrawer(true)}
        sx={{ ml: 1, mr: 2 }}
      >
        <MenuIcon />
      </IconButton>
      {/* <Button >Left</Button> */}
      <Drawer
        anchor="left"
        open={state}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List style={style(location === 'En curso')}>
            <ListItem key="Recorridos" component={Link} to="/main" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <LiveTrackingIcon />
                </ListItemIcon>
                <ListItemText style={style(location === 'En curso')} primary="En curso" />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List style={style(location === 'Historial')}>
            <ListItem key="Historial" component={Link} to="/history" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <HistoryIcon />
                </ListItemIcon>
                <ListItemText style={style(location === 'Historial')} primary="Historial" />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List style={style(location === 'Reporte')}>
            <ListItem key="Reporte" component={Link} to="/lastcheckpoint" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <HistoryIcon />
                </ListItemIcon>
                <ListItemText style={style(location === 'Reporte')} primary="Última Checada" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default TemporaryDrawer;
