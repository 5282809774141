/* eslint-disable max-len */
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import COLORS from './styles/Colors';
import TrackPointsRow from './TrackPointsRow';

const StyledTable = styled(Table)(() => ({
  borderRadius: 0,
  '& .MuiTableCell-head': {
    color: COLORS.SLATE50,
    backgroundColor: COLORS.SLATE700,
    borderRadius: 0,
    padding: '0.25rem 0.5rem',
  },
  '& .MuiTableCell-body': { padding: '0.25rem 0.5rem' },
}));

const BaseTableHeader = ({ typeTable }) => {
  const columnArray = [];
  const addColumnObj = (field, headerName, width, align, isMinWidth) => {
    columnArray.push({
      field,
      headerName,
      width,
      align,
      isMinWidth,
    });
  };
  addColumnObj('track', 'Recorrido', '4rem', 'left', true);
  addColumnObj('driver', 'Conductor', '4rem', 'left', true);
  addColumnObj('hour', 'Hora', '2rem', 'left', true);
  addColumnObj('unit', 'Unidad', '3rem', 'right', true);
  if (typeTable === 'historyScreen') {
    addColumnObj('status', 'Status', '5rem', 'left', true);
  }
  addColumnObj('point', 'Punto', '7.5rem', 'left', false);
  addColumnObj('datePoint', 'Hora', '2rem', 'left', true);
  addColumnObj('diff', 'Diff.', '2rem', 'left', true);
  // addColumnObj('defaultMins', 'Est.', '2rem', 'right', true);
  // addColumnObj('realMins', 'Tiempo', '3rem', 'right', true);
  if (typeTable === 'mainScreen') {
    addColumnObj('mainActions', '', '7rem', 'center', true);
  }
  return (
    <TableHead style={{ borderRadius: 0 }}>
      <TableRow>
        {columnArray.map(({
          field,
          headerName,
          width,
          align,
          isMinWidth,
        }) => {
          const widthObj = (isMinWidth) ? { minWidth: width } : { width, maxWidth: '5rem' };
          return (
            <TableCell
              key={field}
              align={align}
              variant="head"
              padding="none"
              sx={{ ...widthObj, whiteSpace: 'unset' }}
            >
              {headerName}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

const searchFilter = (row, searchValue, unidades) => {
  const removeTilde = (text) => text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  const matcher = new RegExp(`.*${removeTilde(searchValue.toLowerCase())}.*`);
  const unidad = unidades.find((u) => u.idUnidad === row.idUnidad);
  if (typeof unidad?.unidad !== 'undefined') {
    return (matcher.test(removeTilde(row.nombreRuta.toLowerCase()))
      || matcher.test(removeTilde(unidad.unidad.toLowerCase()))
      || matcher.test(removeTilde(row.nombreOperador.toLowerCase())));
  }
  return (matcher.test(removeTilde(row.nombreRuta.toLowerCase()))
    || matcher.test(removeTilde(row.nombreOperador.toLowerCase())));
};

const CollapsibleTable = ({
  currentTracks,
  checadas,
  unidades,
  pointsRoute,
  liftingAction,
  visibleStateRows,
  typeTable,
  filterString,
  filtroRutas,
}) => (
  <TableContainer component={Paper} style={{ borderRadius: 0 }}>
    <StyledTable stickyHeader aria-label="collapsible table">
      <BaseTableHeader typeTable={typeTable} />
      <TableBody>
        {currentTracks
          .filter((row) => searchFilter(row, filterString, unidades))
          .filter((row) => filtroRutas.includes(row.idControlRuta))
          .filter((f) => visibleStateRows.includes(f.stateRow))
          .sort((a, b) => new Date(b.inicio) - new Date(a.inicio))
          .map((row) => (
            <TrackPointsRow
              key={row.id}
              row={row}
              checadas={checadas}
              unidades={unidades}
              pointsRoute={pointsRoute}
              liftingAction={liftingAction}
              visibleStateRows={visibleStateRows}
              typeTable={typeTable}
            />
          ))}
      </TableBody>
    </StyledTable>
    {currentTracks.filter((f) => visibleStateRows.includes(f.stateRow)).length === 0 && (
      <span>No hay registros para esta fecha.</span>
    )}
  </TableContainer>
);

export default CollapsibleTable;
